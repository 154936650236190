<script setup lang="ts">
useHead({
  meta: [
    { name: 'viewport', content: 'width=device-width, initial-scale=1' }
  ],
  link: [
    { rel: 'icon', href: '/favicon.ico' }
  ],
  htmlAttrs: {
    lang: 'en'
  }
})

useSeoMeta({
  ogImage: 'https://uimire/social-card.png',
  twitterImage: 'https://uimire/social-card.png',
  twitterCard: 'summary_large_image'
})

const showPrintModal = ref(false);
</script>

<template>
  <div>
    <Header class="noprint" />

    <UMain>
      <NuxtPage @printModal="showPrintModal = true" />
    </UMain>

    <Footer class="noprint" />

    <UNotifications />

    <UModal v-model="showPrintModal">
      <div class="p-4">
        Pentru a exporta rezultatul in format PDF, foloseste comanda nativa de printare a browser-ului,
        <UKbd>
          CTRL+P
        </UKbd>
        pe
        <span class="font-semibold">
          Windows
        </span>
        sau
        <UKbd>
          CMD+P
        </UKbd>
        pe
        <span class="font-semibold">
          macOS
        </span>
        . Alege optiunea de printare in format PDF si salveaza fisierul.
      </div>
    </UModal>
  </div>
</template>

<style>
.printonly {
  display: none;
}

@media print {
  .printonly {
    display: block;
  }

  .noprint {
    display: none;
  }

  #print {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: white;
  }
}

.page-enter-active,
.page-leave-active {
  transition: all 0.1s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(5px);
}
</style>